.scarves-shawls-region, .blankets-throws-region, .knitwear-region {
  width: 100% !important;
  position: relative;
  padding: 0 !important;

  .feature-product {
    position: absolute;
    max-width: 480px;
    background: rgba(255, 255, 255, 0.8);
    padding: 20px;
    bottom: 20px;
    right: 0;

    .text-title {
      margin: 0 0 10px;
      font-size: 18px;
      line-height: 26px;
      font-weight: 600;
    }

    .text-body {
      p {
        margin: 10px 0;
        font-size: 12px;
        line-height: 20px;
      }
    }
  }
}

.blankets-throws-region, .knitwear-region {
  margin-top: 20px;
}

.knitwear-region {
  margin-bottom: 30px;
}

.blankets-throws-region {
  .feature-product {
    left: 0;
    right: auto;
  }
}

.imagesleft-textright-block, .imagesright-textleft-block {
  position: relative;
  overflow: hidden;
  margin-bottom: 30px;

  .views-field-field-feature-image {
    width: 100%;
  }

  .views-field-nothing {
    max-width: 100%;
    padding: 20px 0;

    .text-title {
      margin: 0 0 20px;
      font-size: 18px;
      line-height: 26px;
      font-weight: 600;
    }

    .text-body {
      font-size: 14px;
      line-height: 22px;
    }
  }
}

#production-description {
  padding: 25px 0;
  .text-title {
    text-align: center;
    font-size: 20px;
    line-height: 28px;
    font-weight: 400;
    margin: 0 0 30px;
  }
}

.node-product {
  .field-name-field-feature-image {
    margin-top: 30px;
    .field-items {
      display: flex;
      flex-wrap: wrap;
    }

    .field-item {
      width: 100%;
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

//Responsive
@media only screen and (max-width: 768px) {
  #production-description {
    padding: 50px 0;
  }

  .scarves-shawls-region,
  .blankets-throws-region,
  .knitwear-region {
    .feature-product {
      position: static;
      max-width: 100%;
      padding: 30px;
      text-align: center;
    }
  }
}

@media only screen and (max-width: 650px) {
  .scarves-shawls-region,
  .blankets-throws-region,
  .knitwear-region {
    .feature-product {
      position: static;
      max-width: 100%;
      padding: 30px;
      text-align: center;
    }
  }
}

@media only screen and (min-width: 600px) {
  .node-product {
    .field-name-field-feature-image {
      .field-item {
        padding: 0 15px;
        width: 50%;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  .node-product {
    .field-name-field-feature-image {
      .field-item {
        width: 33.33%;
      }
    }
  }

  .imagesleft-textright-block, .imagesright-textleft-block {

    .views-field-field-feature-image {
      width: 50%;
      float: left;
    }

    .views-field-nothing {
      max-width: 50%;
      float: left;
      padding: 30px 100px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;

      .text-title {
        margin: 1.5rem 0;
        font-size: 1.5rem;
        line-height: 2.25rem;
      }

      .text-body {
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  .imagesright-textleft-block {
    .views-field-field-feature-image {
      float: right;
    }

    .views-field-nothing {
      float: right;
      left: 0;
      right: auto;
    }
  }
}