.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px 10px;
  position: fixed;
  z-index: 999;
  top: 0;
  left: 0;
  transition: transform .5s ease;
  width: 100%;
  background: #fff;
  box-shadow: 0 0 10px 1px rgba(0, 0, 0, 0.2);

  &.is-hidden{
    transform: translateY(-100%);
  }

  &.is-show{
    transform: translateY(0);
  }

  .region-header {
    flex: 1;
  }

  .sf-main-menu {
    float: right;

    li {
      margin-right: 20px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .sf-menu {

    .active-trail {
      > a {
        border-bottom: 1px solid #000;
      }
    }
    a {
      font-size: 13px;
      padding-bottom: 5px;
      font-weight: normal;
      text-transform: uppercase;
      color: #545454;

      &:hover, &:focus {
        color: darken(#545454, 10%);
      }
    }

    ul {
      background: rgba(255, 255, 255, 0.8);
      padding: 20px;

      li {
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  &__logo-image {
    width: 82px;
  }
}

.sf-menu li:hover > ul,
.sf-menu li.sfHover > ul {
  top: 15px;
}

.mean-container {

  .mean-nav {
    width: 100%;
    top: 118px;
    margin-top: 0;
    background: #f3f3f3;
    position: fixed;
    z-index: 999;
    transition: transform .5s ease;

    &.is-hidden{
      transform: translateY(-150%);
    }

    &.is-show{
      transform: translateY(0);
    }

    ul li a {
      color: #333;
      border-top: 1px solid rgba(226, 226, 226, 0.5) !important;

      &:hover {
        background: #252525;
        background: rgba(111, 111, 111, 0.1);
      }

      &.mean-expand {
        border-left: 1px solid rgba(226, 226, 226, 0.5) !important;
        border-bottom: 1px solid rgba(226, 226, 226, 0.5) !important;
        &:hover {
          background: rgba(218, 218, 218, 0.9);
        }
      }
    }
  }

  a.meanmenu-reveal {
    width: 25px;
    height: 25px;
    color: #000;
    position: fixed;
    z-index: 999;
    top: 30px;
    transition: transform .5s ease;

    &.is-hidden{
      transform: translateY(-150%);
    }

    &.is-show{
      transform: translateY(0);
    }

    span {
      background: #000;
      height: 2px;
      margin-top: 6px;
    }
  }
}

.mean-container .mean-bar {
  background: transparent;
  padding: 0;
  position: absolute;
  top: -130px;
}

@media only screen and (min-width: 768px) {
  .header {
    padding: 0 35px 10px;
  }

  .logged-in {
    .header {
      padding-top: 30px;
    }
  }
}
