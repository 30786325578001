.fabrics-region {
  width: 100% !important;
  padding: 0 !important;

  .view-components {
    margin-bottom: 30px;
    position: relative;
    overflow: hidden;

    .views-field-field-feature-image {
      img {
        width: 50%;
        float: left;
      }
    }

    .feature-product {
      position: absolute;
      max-width: 480px;
      background: rgba(255, 255, 255, 0.8);
      padding: 20px;
      bottom: 20px;
      right: 0;

      .text-title {
        margin: 0 0 10px;
        font-size: 18px;
        line-height: 26px;
        font-weight: 600;
      }

      .text-body {
        p {
          margin: 10px 0;
          font-size: 12px;
          line-height: 20px;
        }
      }
    }
  }

  .product-full {
    .views-field-field-feature-image {
      img {
        width: 100%;
        float: left;
      }
    }
    .feature-product {
      left: 0;
      right: auto;
    }
  }
}

//Responsive
@media only screen and (max-width: 650px) {
  .fabrics-region .view-components {
    margin-bottom: 0;

    .feature-product {
      position: static;
      padding: 20px 0;
      max-width: 100%;
      clear: both;
    }
  }
}