.main-wrap {
  .layout-center {

    .layout-swap {
      padding: 0;
      margin: 0;
    }

    .layout-3col__full[role="main"] {
      margin: 0;
      padding: 0;
    }

    .panels-flexible-3 .panels-flexible-row {
      padding: 0;
    }

    .flexslider {
      border: 0;
      margin-bottom: 0;

      .slides > li {
        position: relative;
      }
    }

    .panels-flexible-region-3-center {
      width: 100%;
    }

  }
}

.hero-section__title {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  text-align: center;
  z-index: 1;
  color: #fff;
  margin: 0 auto;
  font-weight: 600;
  font-size: 18px;
  max-width: 200px;

  p {
    margin: 0;
  }
}

#homepage {
  .panels-flexible-region-3-products_and_fabrics {
    width: 100%;
  }
}

#scarves-shawls, #knitwear {
  .views-field-field-feature-image {
    .field-content {
      overflow: hidden;

      > img {
        width: 50%;
        float: left;
      }
    }
  }
}

.product-row, .story-row, .verification-row {
  padding: 0 10px !important;
}

.product-block {
  margin-bottom: 10px;
  position: relative;

  .views-field-body {
    max-width: 100%;
    background: rgba(255, 255, 255, 0.8);
    padding: 20px 0;

    .text-title {
      margin: 0 0 20px;
      font-size: 18px;
      line-height: 26px;
      font-weight: 600;
    }

    .text-link {
      margin-top: 10px;

      a {
        border-bottom: 1px solid #000;
        font-weight: 400;
      }
    }

    p {
      margin: 10px 0;
      font-size: 14px;
      line-height: 22px;
    }
  }
}

#about-product {
  text-align: center;
  max-width: 600px;
  margin: 0 auto;
  padding: 20px 0;
  .text-title {
    font-size: 16px;
    line-height: 24px;
    margin: 0 0 10px;
  }

  p {
    margin: 0;
    font-size: 14px;
    line-height: 22px;
  }
}

#blankets-throws {
  .views-field-body {
    left: 0;
    right: auto;
    bottom: 20px;
  }
}

.story-row {
  .panels-flexible-region-3-notice_section {
    width: 100%;
  }
}

#our-stories, .contact-row {
  position: relative;

  .views-field-field-feature-image {
    img {
      height: 455px;
      object-fit: cover;
    }
  }

  .views-field-nothing {
    background: rgba(255, 255, 255, 0.8);
    text-align: center;
    max-width: 390px;
    margin: 0 auto;
    padding: 30px;
  }

  .text-title {
    font-size: 18px;
    font-weight: 400;
    margin: 0 0 20px;
  }

  .text-link {
    border-bottom: 1px solid #000;
    font-weight: 400;
    display: inline-block;
  }

  p {
    font-size: 14px;
    margin: 0 0 20px;
  }
}

.logged-in {
  #our-stories, .contact-row {
    .views-field-nothing {
      padding-bottom: 40px;
      margin-bottom: 20px;
    }
  }
}

.verification-row {
  .views-exposed-widgets {
    margin: 0 auto;
    padding: 20px 0 0;
    max-width: 765px;
    text-align: center;
  }

  .views-exposed-form .views-exposed-widget {
    padding: 0;
    width: 100%;
    &.views-submit-button {
      margin: 25px 0 30px;
      .form-submit {
        margin-top: 0;
      }
    }
  }

  #edit-field-trade-mark-wrapper {
    position: relative;
    label {
      display: block;
    }

    .views-widget {
      display: block;
    }
  }

  input {
    &.form-text {
      border: 0;
      border-bottom: 1px solid #4a4a4a;
      margin-left: 15px;
      outline: none;
    }

    &.form-submit {
      margin: 0;
      background: #4a4a4a;
      outline: none;
      border: none;
      padding: 10px 20px;
      color: #fff;
      font-size: 14px;
      font-weight: 400;

      &:hover, &:focus {
        background: darken(#4f4c4d, 20%);
      }
    }
  }
}

.form-submit {
  margin: 0;
  background: #4a4a4a;
  outline: none;
  border: none;
  padding: 10px 20px;
  color: #fff;
  font-size: 14px;
  font-weight: 400;

  &:hover, &:focus {
    background: darken(#4f4c4d, 20%);
  }
}

.front {
  #colorbox {
    color: #fff;
    font-weight: 400;
    font-size: 18px;  

    .message {
      margin: 20px 0;

      + p {
        font-size: 16px;
        a{
          color: red;
        }
      }
    }
  }

  #cboxWrapper {
    background: transparent;
  }

  #cboxContent {
    background: url("../images/pv-bg.jpg") no-repeat top center;
    padding: 50px;
    position: relative;

    &:before {
      content: "";
      position: absolute;
      background: rgba(0, 0, 0, 0.5);
      top: 0;
      left: 0;
      right: 0;
      border: 0;
      width: 100%;
      height: 100%;
    }
  }

  #cboxLoadedContent {
    margin-bottom: 0;
    position: relative;
  }

  #cboxClose {
    top: 0;
    bottom: auto;
  }
}



//Responsive
//max-width-queries
@media only screen and (max-width: 768px) {
  #our-stories, .contact-row {
    .views-field-field-feature-image img {
      height: auto;
    }
  }
}
//min-width-queries
@media only screen and (min-width: 768px) {
  .product-row, .story-row, .verification-row {
    padding: 0 30px !important;
  }

  #about-product {
    padding: 50px 0;

    .text-title {
      font-size: 20px;
      line-height: 28px;
      margin: 0 0 20px;
    }

    p {
      margin: 0;
      font-size: 16px;
      line-height: 24px;
    }
  }

  .product-block {
    margin-bottom: 30px;

    .views-field-body {
      position: absolute;
      max-width: 480px;
      padding: 20px;
      bottom: 20px;
      right: 0;

      p {
        font-size: 12px;
        line-height: 20px;
      }
    }
  }

  .slider-row {
    margin: 0 -30px !important;
  }

  .hero-section__title {
    font-size: 28px;
    max-width: 500px;
  }

  #our-stories, .contact-row {

    .views-field-nothing {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      right: 0;
    }
  }

  .verification-row {
    .views-exposed-widgets {
      padding: 70px 0 0;
      text-align: left;
    }

    .views-exposed-form .views-exposed-widget {
      width: auto;
      padding: .5em 1em 0 0;
      display: inline-block;
      &.views-submit-button {
        margin: 0;
        padding-right: 0;
      }
    }

    #edit-field-trade-mark-wrapper {
      position: relative;
      label {
        display: inline-block;
      }

      .views-widget {
        display: inline-block;
      }

      .description {
        position: absolute;
        right: 10px;
        bottom: -26px;
      }
    }

    input {
      &.form-text {
        border: 0;
        border-bottom: 1px solid #4a4a4a;
        margin-left: 15px;
        outline: none;
      }

      &.form-submit {
        margin: 0;
        background: #4a4a4a;
        outline: none;
        border: none;
        padding: 10px 20px;
        color: #fff;
        font-size: 14px;
        font-weight: 400;

        &:hover, &:focus {
          background: darken(#4f4c4d, 20%);
        }
      }
    }
  }
}