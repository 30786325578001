// infieldLabel styles
form .form-item input.form-text,
form .form-item input.form-email,
form .form-item textarea{
  padding: 10px;
}

form .form-actions input.button{
  background: #5167A6;
  border: 1px solid #5167A6;
  color: #fff;
  padding: 10px 20px;
  border-radius: 2px;
  transition: all 0.3s ease-in-out;

  &:hover, &:focus {
    background: darken(#5167A6, 10%);
    border: 1px solid darken(#5167A6, 10%);
    transition: all 0.3s ease-in-out;
  }
}

form .webform-component-textfield, 
form .webform-component-email, 
form .webform-component-textarea{
  display: block;
  position: relative;
}

form .webform-component-textfield label, 
form .webform-component-email label, 
form .webform-component-textarea label {
  bottom: 0;
  color: #777;
  display: block;
  left: 25px;
  line-height: 39px;
  margin: 0;
  right: 0;
  top: 5px;
}

form .webform-component-textfield label, 
form .webform-component-email label, 
form .webform-component-textarea label {
  cursor: text;
  font-size: 14px;
  padding: 0 10px;
  position: absolute;
  white-space: nowrap;
}

form .form-item.placeholder-focus label {
  background-color: rgba(0, 0, 0, 0.85);
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
  bottom: auto;
  color: #fff;
  line-height: 32px;
  right: auto;
  top: -40px;
}

.no-rgba .placeholder-focus label {
  background-color: #111;
}

form .form-item.placeholder-focus label:after {
  border-top: 5px solid rgba(0, 0, 0, 0.8);
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  bottom: -5px;
  content: '';
  display: block;
  height: 0;
  left: 10px;
  position: absolute;
}

.no-rgba .placeholder-focus label:after {
  border-top-color: #111;
}

form .form-item.infield-label-image.placeholder-focus label {
  top: -122px;
}

form .form-item.placeholder-hide label {
  bottom: auto;
  left: -9999px;
  right: auto;
  top: -9999px;
}

//Contact form
.section-contact {
  .webform-client-form {
    max-width: 585px;
    margin: 20px auto 0;
    padding: 0 10px;

    > div {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -20px;

      > div {
        width: 50%;
        margin: 0 0 20px;
        padding: 0 20px;

        &:last-child {
          margin-bottom: 0;
        }

        &.webform-component-textarea {
          width: 100%;
        }
      }
    }

    label {
      color: #4a4a4a;
      font-weight: normal;
    }

    textarea {
      border: 1px solid #e2e2e2;
    }
    input[type="text"],
    input[type="email"] {
      border: 1px solid #e2e2e2;
      height: 50px;
      padding: 15px;
      width: 100%;
    }

    [type="submit"] {
      background: transparent;
      border: 1px solid #383838;
      color: #333;
      font-weight: normal;
      font-size: 14px;
      padding: 15px 30px;
      transition: all 0.3s ease-in-out;

      &:hover, &:focus {
        border: 1px solid #383838;
        background: #4a4a4a;
        color: #fff;
        transition: all 0.3s ease-in-out;
      }
    }
  }

  .field-type-text-with-summary {
    max-width: 580px;
    margin: 0 auto;
    text-align: center;
    padding: 30px 0;

    p:first-child {
      margin: 0 0 45px;
    }

    p {
      span {
        margin-bottom: 10px;
        display: block;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

//Responsive
//max-width-queries
@media only screen and (max-width: 480px) {
  .section-contact .webform-client-form {
    > div > div, [type="submit"] {
      width: 100%;
    }
  }
}
//min-width-queries
@media only screen and (min-width: 768px) {
  .section-contact {
    .field-type-text-with-summary {
      padding: 100px 0 30px;

      p {
        span {
          margin-bottom: 0;
          margin-right: 30px;
          display: inline-block;

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}