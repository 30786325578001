#stories-list-block, .view-id-news {
  .view-content {
    display: flex;
    flex-wrap: wrap;
  }

  .story-item, .news-item {
    font-weight: 400;
    font-size: 14px;
    width: 100%;
    margin-bottom: 25px;
  }

  .views-field-field-story-hero-image, .views-field-field-feature-image {
    margin-bottom: 25px;
  }

  .views-field-title {
    font-size: 16px;
    font-weight: 600;
  }
}

.view-id-news .news-item {
  border: 2px solid #e2e2e2;
  width: 100%;
  padding: 20px;
  margin: 15px;

  .views-field-view-node {
    margin-top: 30px;

    a {
      text-decoration: underline;
    }
  }

}

.logged-in {
  .producer-item, .story-item {
    position: relative;
  }
  #stories-list-block .story-item {
    padding-bottom: 35px;
  }
}



#producers-block {
  .view-content {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-lines: multiple;
    -moz-box-lines: multiple;
    box-lines: multiple;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin-bottom: 50px;

    .producer-item {
      background: #fafafa;
      border: 1px solid #fafafa;
      text-align: center;
      padding: 40px 25px;
      width: 100%;

      &:nth-of-type(even) {
        background: #fff;
      }

      .views-field-title {
        font-size: 18px;
        font-weight: 400;
        margin-bottom: 10px;
      }

    }
  }
}

@media only screen and (min-width: 590px) {
  #producers-block {
    .view-content {
      .producer-item {
        width: 50%;
      }
    }
  }
}

@media only screen and (min-width: 600px) {
  #stories-list-block, .view-id-news {
    .story-item, .news-item {
      width: 50%;
      padding: 0 20px;
    }
  }

  .view-id-news .news-item {
    width: 44%;
  }
}

@media only screen and (min-width: 920px) {
  #stories-list-block, .view-id-news {
    .story-item, .news-item {
      width: 33.33%;
    }
  }

  #producers-block {
    .view-content {
      .producer-item {
        width: 33.33%;
      }
    }
  }
}

@media only screen and (min-width: 975px) {
  .view-id-news .news-item {
    width: 30%;
  }
}

@media only screen and (min-width: 1024px) {
  .view-id-news .news-item {
    padding: 40px;
  }
}