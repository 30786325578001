*, :after, :before {
  box-sizing: border-box;
}

/* Remove padding and margin */
html, body {
  margin: 0;
  padding: 0;
  border: 0;
  height: 100%;
  width: 100%;
}

body {
  font: 300 16px/1.5 "Open Sans", sans-serif;
  color: #333;
  background: #fff;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  word-wrap: break-word;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
  position: relative;
}

.layout-center {
  max-width: 1280px;
  padding: 0;
}

.content-wrap {
  padding-top: 120px;
}

.content-inner {
  padding: 20px 10px;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

a {
  color: #000;
  text-decoration: none;

  &:hover, &:focus {
    color: lighten(#000, 10%);
  }

  &:visited {
    color: #000;
  }
}

p {
  margin: 10px 0;
}

img, svg {
  display: block;
  width: 100%;
}

.title-wrap {
  position: relative;

  .title-sec {
    text-align: center;
    max-width: 450px;
    margin: 0 auto;
    padding: 0 10px;
  }

  .page-title {
    margin: 0 0 20px;
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
  }

  .sub-title {
    margin: 0;
    font-size: 14px;
    line-height: 24px;
    font-weight: 400;
  }

  .region-feature-image {
    .views-field-field-feature-image {
      .field-content {
        background: #000;

        img {
          opacity: 0.6;
        }
      }
    }
  }
}

//Flexslider
.flexslider:hover .flex-direction-nav {
  a {
    width: 45px;
    height: 70px;

    &:before {
      font-size: 16px;
      height: 100%;
      background: rgba(255, 255, 255, 0.8);
      line-height: 40px;
      padding: 15px;
    }
  }

  .flex-prev {
    left: 0;
  }

  .flex-next {
    right: 0;
  }
}

.flexslider {
  border: 0;
  margin-bottom: 0;

  .slides > li {
    position: relative;

    .views-field-title {
      position: absolute;
      top: 50%;
      left: 0;
      right: 0;
      transform: translateY(-50%);
      text-align: center;
      z-index: 1;
      margin: 0 auto;
      font-weight: 600;
      font-size: 18px;
      max-width: 200px;

      a {
        color: #fff;
      }
    }
  }
}

//Region
.panels-flexible-region {
  width: 100% !important;
}

.panels-flexible-row {
  padding: 0 !important;
}

.views-field-edit-node {
  position: absolute;
  bottom: 0;
  left: 0;
  text-align: center;
  right: 0;

  a {
    background: #4f4c4d;
    font-size: 14px;
    padding: 5px 15px;
    color: #fff;
    margin-top: 10px;
    font-weight: normal;
    display: inline-block;

    &:hover, &:focus {
      background: darken(#4f4c4d, 20%);
    }
  }
}

.logged-in {
  #about-product, #production-description, #stories-list-block, #producers-block {
    .contextual-links-region {
      padding-bottom: 40px;
    }
  }
  #our-purpose {
    .view-components.contextual-links-region {
      padding-bottom: 40px;
    }
  }
}

//Responsive
@media only screen and (min-width: 480px) {
  .title-wrap {
    position: relative;

    .title-sec {
      position: absolute;
      top: 50%;
      color: #fff;
      text-align: center;
      transform: translateY(-50%);
      left: 0;
      right: 0;
      max-width: 450px;
      padding: 0 10px;
    }

    .page-title {
      font-size: 24px;
      line-height: 32px;
    }

    .sub-title {
      font-size: 16px;
      line-height: 24px;
    }

  }
}
@media only screen and (min-width: 768px) {
  .content-inner {
    padding: 0 30px 70px;

    .text-values {
      h2 {
        text-align: center;
        font-size: 20px;
        line-height: 28px;
        font-weight: 400;
        margin: 0;
      }
    }

    .text-origins {
      max-width: 600px;
      margin: 30px auto;

      h3 {
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;
        text-align: center;
        margin: 0;
      }
    }
  }

  .flexslider {
    .slides > li {
      .views-field-title {
        font-size: 28px;
        max-width: 500px;
      }
    }
  }
}

@media only screen and (min-width: 1024px) {
  .title-wrap .region-feature-image .views-field-field-feature-image .field-content {
    height: 650px;

    img {
      height: 100%;
      object-fit: cover;
    }
  }
}