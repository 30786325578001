.footer {
  padding: 0 10px;
  overflow: hidden;
  text-align: center;

  &-wrap {
    background: #f9f9f9;
    padding: 50px 0;
  }

  #block-views-partners-block-1 {
    width: 100%;
    float: left;
    overflow: hidden;
  }

  #block-views-components-block-2 {
    float: left;
    width: 100%;
    padding: 0 20px;
    text-align: center;

    .views-field-body {
      margin-bottom: 50px;

      p {
        color: #919191;
        font-size: 14px;
        margin: 0;
      }
    }
  }

  .block-superfish {
    float: left;
    width: 100%;
    margin-bottom: 30px;

    .sf-menu li {
      display: block;
      width: 100%;
      margin-bottom: 15px;

      &:last-child {
        margin-bottom: 0;
      }

      a {
        color: #919191;
        font-size: 16px;
        line-height: 24px;

        &:hover, &:focus {
          color: darken(#919191, 10%);
        }
      }
    }
  }

  #block-block-5 {
    float: left;
    width: 100%;

    ul {
      margin: 0;
      padding: 0;
      list-style: none;

      li {
        margin-bottom: 15px;

        a {
          color: #919191;
          font-size: 16px;

          &:hover, &:focus {
            color: darken(#919191, 10%);
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .block__title {
    margin: 0 0 15px;
    font-size: 16px;
    color: #919191;
    font-weight: 400;
  }

  .view-partners {
    .views-row {
      float: left;
      width: 50%;
      margin-bottom: 30px;

      img {
        width: auto;
        margin: 0 auto;
      }
    }
  }
}

.region-bottom {
  padding: 0 30px;
  text-align: center;
  margin-top: 50px;

  p {
    color: #919191;
    font-size: 12px;
  }
}

//Responsive
@media only screen and (min-width: 480px) {
  .footer {
    .block-superfish {
      width: 50%;
      padding-left: 60px;
      margin-bottom: 0;
      text-align: center;
    }
    #block-block-5 {
      width: 50%;
      padding-right: 60px;
      text-align: center;
    }
  }
}

@media only screen and (min-width: 768px) {
  .footer {
    padding: 0 30px;
    text-align: left;

    #block-views-partners-block-1 {
      max-width: 300px;
    }

    #block-views-components-block-2 {
      padding: 0;
      max-width: 56%;
      width: auto;

      .views-field-body {
        margin-bottom: 0;
      }
    }

    .block-superfish {
      clear: both;
    }

    .view-partners {
      .views-row {
        float: left;
        width: auto;
        margin: 0 35px 20px 0;
      }
    }

  }

  .region-bottom {
    padding: 0 30px;
  }
}

@media only screen and (min-width: 1024px) {
  .footer {
    display: flex;

    #block-views-components-block-2 {
      max-width: 50%;
      text-align: left;
      padding: 0 70px;
    }

    .block-superfish {
      padding: 0;
      text-align: left;
    }

    #block-block-5 {
      padding-right: 0;
      text-align: left;
    }
  }
  .region-bottom {
    text-align: left;
    margin-top: 0;
  }
}