.panels-flexible-region-18-abaout_cp {
  width: 100%;
}

.region-feature-image {
  margin-bottom: 30px;
}

#about-cp, #cp-trademark {

  .views-row {
    overflow: hidden;
    position: relative;

    .views-field-field-feature-image {
      width: 100%;
      margin-bottom: 20px;
    }

    .views-field-nothing {
      max-width: 100%;
      padding: 0 10px 30px;

      .text-title {
        margin: 0 0 20px;
        font-size: 18px;
        font-weight: 600;
      }

      .text-body {
        font-size: 14px;
        line-height: 22px;
      }
    }
  }
}

#about-cp {
  padding: 0 0 30px;
}

#our-values {
  background: #fbfbfb;
  padding: 30px 10px;

  .views-field-nothing {
    .text-values {
      h2 {
        text-align: center;
        font-size: 20px;
        line-height: 28px;
        font-weight: 400;
        margin: 0;
      }
    }
  }

  .text-origins {
    max-width: 600px;
    margin: 30px auto;

    h3 {
      font-size: 14px;
      line-height: 22px;
      font-weight: 400;
      text-align: center;
      margin: 0;
    }

    p {
      font-size: 14px;
      line-height: 22px;
      text-align: center;
    }
  }
}

.grid-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  text-align: center;

  .box-item {
    width: 100%;
    padding: 0 10px;
    margin-bottom: 20px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .text-environment {
    p {
      margin: 0;
    }

    h3 {
      font-size: 18px;
      line-height: 26px;
      margin: 20px 0;
      font-weight: 600;
    }
  }
}

#about-npia {
  padding: 30px 0;
  background: #fff;

  .views-row {
    overflow: hidden;
    position: relative;

    .views-field-field-feature-image {
      width: 100%;
      margin-bottom: 20px;
    }

    .views-field-nothing {
      max-width: 100%;
      padding: 0 10px;

      .text-title {
        margin: 0 0 10px;
        font-size: 18px;
        line-height: 26px;
        font-weight: 600;
      }

      .text-body {
        font-size: 14px;
        line-height: 22px;
      }
    }

    .views-field-edit-node {
      clear: both;
    }
  }
}

#board-members {
  margin: 30px auto 0;
  padding: 0;

  .pane__title {
    text-align: center;
    font-size: 18px;
    line-height: 26px;
    font-weight: 400;
    margin: 0 0 10px;
    height: 100%;
    transition: all 0.2s, max-height 4.8s;
    cursor: pointer;

    &.active {
      margin-bottom: 10px;

      ~ .view-board-members {
        opacity: 0;
        pointer-events: none;
        height: 0;
        transition: all 0.2s, max-height 4.8s;
      }
    }
  }

  .view-board-members {
    opacity: 1;
    pointer-events: none;
    transition: all 0.2s, max-height 4.8s;
  }

  .view-content {
    display: flex;
    flex-wrap: wrap;

    .member-item {
      width: 100%;
      font-weight: 400;
      font-size: 14px;
      margin-bottom: 20px;

      .views-field-field-profile-image {
        margin-bottom: 20px;
      }

      .views-field-title {
        font-weight: 600;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}

.purpose-region {
  background: #f4f4f4;
  padding: 20px 10px !important;

  .purpose-region {
    padding: 0 !important;
  }

  .views-field-nothing .text-purpose {
    font-weight: 400;

    h2 {
      text-align: center;
      font-size: 18px;
      line-height: 26px;
      font-weight: 400;
      margin: 0 0 20px;
    }

    .grid-box {
      flex-wrap: wrap;
      max-width: 810px;
      margin: 0 auto;
      text-align: center;
    }

    .box-item {
      width: 100%;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }

      h3 {
        margin: 0;
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
      }

      p {
        margin: 10px 0;
      }
    }
  }

  .text-objective {
    max-width: 1024px;
    margin: 10px auto 0;
    text-align: center;

    ul {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      flex-wrap: wrap;

      li {
        width: 100%;
        margin-bottom: 20px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }

  .org-structure {
    max-width: 1024px;
    margin: 30px auto 0;
    font-weight: 600;

    h2 {
      text-align: center;
      font-size: 18px;
      line-height: 26px;
      font-weight: 400;
      margin: 0 0 20px;
      height: 100%;
      transition: all 0.2s, max-height 4.8s;
      cursor: pointer;

      &.active {
        margin-bottom: 0;
        ~ ul {
          opacity: 0;
          pointer-events: none;
          height: 0;
          transition: all 0.2s, max-height 4.8s;
        }
      }
    }

    ul {
      opacity: 1;
      pointer-events: auto;
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      flex-wrap: wrap;

      li {
        width: 100%;
        padding: 20px;
        border: 1px solid #868686;
        text-align: center;
        margin: 10px;
        font-weight: 400;
      }
    }
  }
}

#cp-trademark {
  margin-top: 30px;
}

//Responsive

@media only screen and (min-width: 480px) {
  #board-members {
    .view-content {
      .member-item {
        width: 50%;
        padding: 0 10px;
      }
    }
  }

  .purpose-region .org-structure ul li {
    width: 45%;
  }

  .grid-box {
    .box-item {
      width: 50%;
      padding: 0 10px;
    }
  }

  .region-feature-image {
    margin-bottom: 0;
  }
}

@media only screen and (min-width: 768px) {
  .purpose-region {
    background: #f4f4f4;
    padding: 20px 10px !important;

    .purpose-region {
      padding: 0 !important;
    }

    .views-field-nothing .text-purpose {
      font-weight: 400;

      h2 {
        font-size: 20px;
        line-height: 28px;
        margin: 0 0 30px;
      }

      .grid-box {
        text-align: left;
      }

      .box-item {
        width: 50%;
        padding: 0 20px;
        margin-bottom: 0;

        h3 {
          margin: 0;
          font-size: 16px;
          line-height: 24px;
          font-weight: 600;
        }

        p {
          margin: 10px 0;
        }
      }
    }

    .text-objective {
      margin: 30px auto 0;
      text-align: left;

      ul {
        li {
          width: 33.33%;
          padding: 0 35px 20px;
          margin-bottom: 0;
        }
      }
    }

    .org-structure {
      margin: 50px auto 0;

      h2 {
        font-size: 20px;
        line-height: 28px;
        margin: 0 0 30px;
      }

      ul {
        padding: 0;
        margin: 0;
        list-style: none;
        display: flex;
        flex-wrap: wrap;

        li {
          width: 31%;
          padding: 20px 70px;
        }
      }
    }
  }

  .front {
    .region-feature-image {
      margin-bottom: 0
    }
  }

  .region-feature-image {
    margin-bottom: 30px;
  }
}

@media only screen and (min-width: 900px) {
  #board-members {
    margin-top: 50px;
    padding: 0 30px;

    .pane__title {
      font-size: 20px;
      line-height: 28px;
      margin: 0 0 30px;
    }

    .view-content {

      .member-item {
        width: 25%;
        font-weight: 400;
        font-size: 14px;
        padding: 20px 10px;
        margin-bottom: 0;

        .views-field-field-profile-image {
          margin-bottom: 20px;
        }

        .views-field-title {
          font-weight: 600;
        }

        &:nth-child(1), &:nth-child(2), &:nth-child(3) {
          width: 33.33%;
        }
      }
    }
  }

  .purpose-region {
    padding: 65px 20px 30px !important;
  }

  #about-npia {
    .views-row {
      .views-field-field-feature-image {
        width: 50%;
        float: right;
        margin-bottom: 0;
      }

      .views-field-nothing {
        max-width: 50%;
        float: left;
        padding: 30px;
        position: static;

        .text-title {
          margin: 0 0 20px;
          font-size: 24px;
          line-height: 32px;
        }

        .text-body {
          font-size: 16px;
          line-height: 24px;
        }
      }
    }
  }

  #our-values {
    padding: 60px 30px;

    .views-field-nothing {
      .text-values {
        h2 {
          text-align: center;
          font-size: 20px;
          line-height: 28px;
          font-weight: 400;
          margin: 0;
        }
      }
    }

    .text-origins {
      max-width: 600px;
      margin: 30px auto;

      h3 {
        font-size: 14px;
        line-height: 22px;
        font-weight: 400;
        text-align: center;
        margin: 0;
      }

      p {
        font-size: 16px;
        line-height: 24px;
        text-align: left;
      }
    }
  }

  #about-cp, #cp-trademark {
    .views-row {

      .views-field-field-feature-image {
        width: 50%;
        float: left;
        margin-bottom: 0;
      }

      .views-field-nothing {
        max-width: 50%;
        float: left;
        padding: 30px 100px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 0;

        .text-title {
          font-size: 24px;
          font-weight: 600;
        }

        .text-body {
          font-size: 16px;
        }
      }

      .views-field-edit-node {
        clear: both;
      }
    }
  }

  .grid-box {
    text-align: left;
    .box-item {
      width: 33.33%;
      padding: 0 10px;
      margin-bottom: 0;
    }

    .text-environment {
      h3 {
        margin: 40px 0 20px;
      }
    }
  }
}

@media only screen and (min-width: 1100px) {
  #about-npia {
    .views-row {
      .views-field-nothing {
        padding: 30px 100px;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
      }
    }
  }
}