article.node-441 {
  background: #f9f9f9;
  padding: 30px 0;

  h2 {
    text-align: center;
    font-weight: normal;
    font-size: 20px;
    margin: 0 0 15px;
  }

  ul {
    display: flex;
    flex-wrap: wrap;

    li {
      width: 100%;
      background: #fff;
      padding: 20px;
      text-align: center;
      margin: 10px;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;

      &:last-child {
        width: 100%;
        text-align: left;
        margin-bottom: 0;

        strong {
          text-align: center;
        }
      }
    }

    strong {
      display: block;
      padding-bottom: 15px;
      border-bottom: 2px solid #f9f9f9;
      margin: 0 -35px;
      font-weight: normal;
      font-size: 18px;
    }
  }

}

//Responsive
@media only screen and (min-width: 600px) {
  article.node-441 {
    padding: 60px 30px;

    h2 {
      font-size: 24px;
      line-height: 32px;
      margin: 0 0 30px;
    }

    ul {

      li {
        padding: 35px;
        font-size: 16px;
      }

      strong {
        padding-bottom: 35px;
      }
    }
  }
}

@media only screen and (min-width: 768px) {
  article.node-441 {
    ul {

      li {
        margin-right: 0;
        width: 48%;
      }
    }
  }
}